! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("opened");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("opened");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-row").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".w-dropdown:has('.w-dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-drop-toggle-link-3").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".w-dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".w-dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	Webflow.require('ix').init([
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "new-dropdown",name: "New Dropdown",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 200ms ease 0ms, height 200ms ease 0ms"}],stepsB: [{height: "0px",transition: "opacity 100ms ease 0ms, height 100ms ease 0ms"}]}]}},
		{slug: "dropdown-list",name: "Dropdown List ",value: {style: {},triggers: [{type: "hover",stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "nav-search-button",name: "Nav Search Button",value: {style: {},triggers: [{type: "click",stepsA: [{display: "block"}],stepsB: [{display: "none"}]},{type: "click",stepsA: [{opacity: 1}],stepsB: [{opacity: 0.55}]}]}},
		{slug: "md-reveal-doctor-search",name: "MD Reveal Doctor Search",value: {style: {},triggers: [{type: "click",selector: ".md-doctor-form-reveal-wrap",stepsA: [{display: "block",height: "0px"},{height: "auto",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"},{display: "none"}]}]}},
		{slug: "md-tooltip-button-hover",name: "MD Tooltip Button Hover",value: {style: {},triggers: [{type: "hover",selector: ".md-advanced-search-tooltip-popup",descend: true,preserve3d: true,stepsA: [{display: "block",opacity: 0,x: "0px",y: "1.5rem",z: "0px"},{wait: 150},{opacity: 1,transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: 150},{opacity: 0,transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms",x: "0px",y: "1.5rem",z: "0px"},{display: "none",opacity: 1,x: "0px",y: "0px",z: "0px"}]},{type: "click",selector: ".md-advanced-search-tooltip-popup",descend: true,preserve3d: true,stepsA: [{display: "block",opacity: 0,x: "0px",y: "1.5rem",z: "0px"},{wait: 150},{opacity: 1,transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: [{opacity: 0,transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms",x: "0px",y: "1.5rem",z: "0px"},{display: "none",opacity: 1,x: "0px",y: "0px",z: "0px"}]}]}},
		{slug: "reveal-left-nav",name: "Reveal Left Nav",value: {style: {},triggers: [{type: "click",selector: ".left-nav-list-content-wrap",siblings: true,stepsA: [{height: "auto",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"}]}]}},
		{slug: "md-patient-comments-reveal-on-load",name: "MD Patient Comments Reveal (on load)",value: {style: {height: "0px"},triggers: []}},
		{slug: "md-show-more-button",name: "MD Show More Button",value: {style: {},triggers: [{type: "click",selector: ".md-patient-comments-reveal-more",stepsA: [{height: "auto",transition: "height 700ms ease 0ms"}],stepsB: []}]}},
		{slug: "loc-reveal-sidebar-search",name: "LOC Reveal Sidebar Search",value: {style: {},triggers: [{type: "click",selector: ".loc-sidebar-form-reveal",stepsA: [{height: "auto",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"}]},{type: "click",selector: ".loc-refine-search-button-image",preserve3d: true,stepsA: [{transition: "transform 500ms ease 0ms",rotateX: "0deg",rotateY: "0deg",rotateZ: "90deg"}],stepsB: [{transition: "transform 500ms ease 0ms",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}]}]}},
		{slug: "for-two-part-button-toggle",name: "For Two Part Button Toggle",value: {style: {},triggers: [{type: "click",selector: ".two-part-button-list",siblings: true,preserve3d: true,stepsA: [{transition: "transform 750ms ease 0",x: "-100%",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "for-two-part-button-list",name: "For Two Part Button List",value: {style: {x: "100%",y: "0px",z: "0px"},triggers: []}},
		{slug: "for-two-part-list-back-button",name: "For Two Part List Back Button",value: {style: {},triggers: [{type: "click",selector: ".two-part-button-list",preserve3d: true,stepsA: [{transition: "transform 750ms ease 0",x: "100%",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "display-none-on-page-load",name: "Display None On Page Load",value: {style: {display: "none"},triggers: []}},
		{slug: "for-grid-view-button",name: "For Grid View Button",value: {style: {opacity: 0.7500000000000009},triggers: [{type: "click",selector: ".shop-item-row-wrap-list-view",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".shop-item-row-wrap-grid-view",stepsA: [{display: "flex"}],stepsB: []},{type: "click",selector: ".pagination-view-by-button",siblings: true,stepsA: [{opacity: 0.3500000000000003,transition: "opacity 200ms ease 0ms"}],stepsB: []},{type: "click",stepsA: [{opacity: 0.7500000000000009,transition: "opacity 200ms ease 0ms"}],stepsB: []}]}},
		{slug: "for-list-view-button",name: "For List View Button",value: {style: {},triggers: [{type: "click",selector: ".shop-item-row-wrap-grid-view",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".shop-item-row-wrap-list-view",stepsA: [{display: "flex"}],stepsB: []},{type: "click",selector: ".pagination-view-by-button",siblings: true,stepsA: [{opacity: 0.3500000000000003,transition: "opacity 200ms ease 0ms"}],stepsB: []},{type: "click",stepsA: [{opacity: 0.7500000000000009,transition: "opacity 200ms ease 0ms"}],stepsB: []}]}},
		{slug: "expand-search-bar",name: "Expand Search Bar",value: {style: {},triggers: []}},
		{slug: "preloader-wrap",name: "Preloader Wrap",value: {style: {display: "flex"},triggers: [{type: "load",preload: true,stepsA: [{wait: 750},{opacity: 0,transition: "opacity 750ms ease 0ms"},{display: "none"}],stepsB: []}]}},
		{slug: "mobile-search-reveal",name: "Mobile Search Reveal",value: {style: {},triggers: [{type: "click",selector: ".header-mobile-search-reveal",stepsA: [{height: "0px"},{height: "auto",transition: "height 500ms ease 0ms"}],stepsB: [{height: "0px",transition: "height 500ms ease 0ms"}]},{type: "click",selector: ".mobile-search-close-icon",descend: true,stepsA: [{display: "block",opacity: 0},{opacity: 1,transition: "opacity 300ms ease 0"}],stepsB: [{display: "none"}]},{type: "click",selector: ".secondary-nav-icon",descend: true,stepsA: [{display: "none"}],stepsB: [{display: "block",opacity: 0},{opacity: 0.4,transition: "opacity 300ms ease 0"}]},{type: "click",selector: ".mobile-search-active-bknd",descend: true,stepsA: [{display: "block",opacity: 0},{opacity: 1,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 0,transition: "opacity 300ms ease 0"},{display: "none"}]}]}},
		{slug: "hide-mobile-header-on-load",name: "Hide Mobile Header On Load",value: {style: {height: "0px"},triggers: []}},
		{slug: "new-interaction",name: "New Interaction",value: {style: {},triggers: []}},
		{slug: "new-nav-drop-toggle",name: "New Nav Drop Toggle ",value: {style: {},triggers: [{type: "dropdown",selector: ".nav-drop-list-new",siblings: true,stepsA: [{height: "0px"},{height: "auto",transition: "height 500ms ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0"}]}]}},
		{slug: "new-nav-drop-list",name: "New Nav Drop List",value: {style: {height: "0px"},triggers: []}},
		{slug: "nav-drop-wrapper",name: "Nav Drop Wrapper",value: {style: {},triggers: [{type: "hover",selector: ".nav-drop-list-element",descend: true,stepsA: [{display: "block",opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 500ms ease 0, opacity 300ms ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"},{display: "none"}]}]}},
		{slug: "nav-drop-list",name: "Nav Drop List",value: {style: {},triggers: [{type: "hover",selector: ".nav-drop-background",siblings: true,stepsA: [{display: "block",opacity: 0},{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: [{opacity: 0,transition: "opacity 200 ease 0"},{display: "none"}]}]}},
		{slug: "slider-upward-shift",name: "Slider Upward Shift",value: {style: {opacity: 1},triggers: [{type: "load",stepsA: [{wait: "350ms",transition: "transform 200 ease 0",x: "0px",y: "-100px",z: "0px",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: []}]}},
		{slug: "hidden-search-dropdown",name: "Hidden Search Dropdown",value: {style: {},triggers: [{type: "click",selector: ".search-hidden-dropdown",stepsA: [{display: "block",opacity: 1,transition: "opacity 350ms ease 0"}],stepsB: [{display: "none",opacity: 0,transition: "opacity 200 ease 0"}]}]}},
		{slug: "tablet-search-field-appear",name: "Tablet Search Field Appear",value: {style: {},triggers: [{type: "click",selector: ".search-wrap-new",stepsA: [{display: "flex",opacity: 1,width: "100%",height: "46px",transition: "width 200 ease 0, opacity 500ms ease 0, height 200 ease 0"}],stepsB: [{opacity: 0,width: "0%",height: "0px",transition: "width 200 ease 0, opacity 200 ease 0, height 200 ease 0"}]}]}},
		{slug: "desktop-open-search-bar",name: "Desktop Open Search Bar",value: {style: {},triggers: [{type: "click",selector: ".search-form-block",stepsA: [{display: "block",opacity: 1,transition: "opacity 500ms ease 0"}],stepsB: [{display: "none",opacity: 0,transition: "opacity 200 ease 0"}]}]}},
		{slug: "slide-text-load-top",name: "Slide Text Load Top",value: {style: {opacity: 0,x: "0px",y: "-100px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: 800,title: "Wait"},{wait: "750ms",opacity: 1,transition: "transform 750ms ease 0, opacity 750ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-top-2",name: "Slide Text Load Top 2",value: {style: {opacity: 0,x: "0px",y: "-25px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1100ms",title: "Wait"},{wait: "750ms",opacity: 1,transition: "transform 750ms ease 0, opacity 750ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-top-3",name: "Slide Text Load Top 3",value: {style: {opacity: 0,x: "0px",y: "-25px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1100ms",title: "Wait"},{wait: "750ms",opacity: 1,transition: "transform 750ms ease 0, opacity 750ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-text-load-bottom",name: "Slide Text Load Bottom",value: {style: {opacity: 0,x: "0px",y: "22px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1500ms",title: "Wait"},{wait: "750ms",opacity: 1,transition: "transform 750ms ease 0, opacity 750ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "nav-triangle-icon-rotate-2",name: "Nav Triangle Icon Rotate 2",value: {style: {},triggers: [{type: "hover",selector: ".nav-drop-icon-2",preserve3d: true,stepsA: [{wait: "450ms",transition: "transform 450ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}],stepsB: [{transition: "transform 250ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "-90deg"}]}]}},
		{slug: "nav-triangle-icon-rotate-1",name: "Nav Triangle Icon Rotate 1",value: {style: {},triggers: [{type: "hover",selector: ".nav-drop-icon-1",preserve3d: true,stepsA: [{wait: "450ms",transition: "transform 450ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}],stepsB: [{transition: "transform 250ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "-90deg"}]}]}},
		{slug: "nav-triangle-icon-rotate-3",name: "Nav Triangle Icon Rotate 3",value: {style: {},triggers: [{type: "hover",selector: ".nav-drop-icon-3",preserve3d: true,stepsA: [{wait: "450ms",transition: "transform 450ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}],stepsB: [{transition: "transform 250ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "-90deg"}]}]}},
		{slug: "nav-triangle-icon-rotate-4",name: "Nav Triangle Icon Rotate 4",value: {style: {},triggers: [{type: "hover",selector: ".nav-drop-icon-4",preserve3d: true,stepsA: [{wait: "450ms",transition: "transform 450ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}],stepsB: [{transition: "transform 250ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "-90deg"}]}]}},
		{slug: "slide-left",name: "Slide Left",value: {style: {opacity: 0,x: "125px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{wait: "300ms",title: "Delay 300 ms"},{opacity: 0.85,transition: "transform 750ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-left-2",name: "Slide Left 2",value: {style: {opacity: 0,x: "125px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{wait: "300ms",title: "Delay 400 ms"},{opacity: 0.85,transition: "transform 750ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-right",name: "Slide Right",value: {style: {opacity: 0,x: "-125px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{wait: "300ms",title: "Delay 300 ms"},{opacity: 0.85,transition: "transform 750ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-right-2",name: "Slide Right 2",value: {style: {opacity: 0,x: "-125px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{wait: "300ms",title: "Delay 300 ms"},{opacity: 0.85,transition: "transform 750ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "delay-transistions",name: "Delay Transistions",value: {style: {opacity: 0},triggers: [{type: "scroll",stepsA: [{wait: "2000ms",opacity: 1,transition: "opacity 500ms ease 0, transform 200 ease 0"}],stepsB: []}]}},
		{slug: "show-search-bar",name: "Show Search Bar",value: {style: {},triggers: [{type: "click",selector: ".hidden-search-bar",stepsA: [{display: "flex",opacity: 1,transition: "opacity 450ms ease 0"}],stepsB: [{display: "none",opacity: 0,transition: "opacity 300ms ease 0"}]}]}}
	]);
});
